import React from 'react'
import { Light } from '../../assets/icons/Light'
import { PoolCover } from '../../assets/icons/PoolCover'

const SinglePool = ({ pool }) => {

    return (
        <>
            <div className={`grid--elem`}>
                <span className={`name`}>{pool.name}</span>
                {pool.objID === 16523 ?
                    <PoolCover className={`cover`} covered={pool.covered} />
                    : ''}
                <div className={`value`}>
                    <span>{pool.value}</span>
                </div>
                {pool.objID === 16523 ?
                    <Light className={`lighting`} activated={pool.lighting} />
                    : ''}
                <span className={`unit`} title="Einheit">°C</span>
            </div>

            {/* {showDetails ?
                <DetailedView
                    showDetails={showDetails}
                    obj={pool}
                    route={`pools`}
                    unit='°C'
                    produced={false}
                    close={closeDetails}
                />
                : ''} */}
        </>
    )
}

export default SinglePool
