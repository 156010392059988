import React from 'react'
import Battery from '../../components/BatteryStorage/Battery'
import BHKW from '../../components/BHKW/BHKW'
import Total from '../../components/Total/Total'
import PV from '../../components/Photovoltaik/PV'
import Charging from '../../components/ChargingStation/ChargingStation'
import HeatEnergy from '../../components/HeatEnergy/HeatEnergy'
import CO2 from '../../components/CO2/CO2'
import Housekeeping from '../../components/Housekeeping/Housekeeping'
import Pool from '../../components/Pool/Pool'


const Screen = () => {

    return (
        <div className={`screen content`}>
            <div className={`screen__content`}>
                <div className={`block multi`}>
                    <Battery />
                    <Total />
                </div>
                <div className={`block`}>
                    <BHKW />
                </div>
                <div className={`block`}>
                    <PV />
                </div>
                <div className={`block`}>
                    <HeatEnergy />
                </div>
                <div className={`block`}>
                    <Charging />
                </div>
                <div className={`block multi custom`}>
                    <Pool />
                    <CO2 />
                    <Housekeeping />
                </div>
            </div>
        </div>
    )
}

export default Screen
