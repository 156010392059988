import React, { useState } from 'react'
import DetailedView from '../../widgets/DetailedView'

const SingleTotal = ({ total }) => {

    const [showDetails, setShowDetails] = useState(false);

    const closeDetails = () => {
        setShowDetails(false);
    }

    return (
        <>
            <div onClick={() => setShowDetails(!showDetails)} className={`grid--elem ${total.value > 0 ? 'active' : ''}`}>
                <span className={`name`}>{total.name}</span>
                <div className={`value`}>
                    <span className={
                        (((total.objID === 25916 && total.value >= 80) ||
                            (total.objID === 25916 && total.value < 17)) ||
                            ((total.objID === 36827 && total.value >= 85) ||
                                (total.objID === 36827 && total.value < 15))) ? 'critical'
                            :
                            (((total.objID === 25916 && total.value >= 55) ||
                                (total.objID === 25916 && total.value < 22)) ||
                                ((total.objID === 36827 && total.value >= 70) ||
                                    (total.objID === 36827 && total.value < 30))) ? 'moderate'
                                :
                                'ok'
                    }>{total.value}</span>
                </div>
                <span className={`unit`} title="Einheit">kW</span>
            </div>

            {showDetails ?
                <DetailedView
                    showDetails={showDetails}
                    obj={total}
                    route={`total`}
                    unit='kW'
                    produced={true}
                    close={closeDetails}
                    minValue={total.objID === 36827 ? 5 : null}
                />
                : ''}
        </>
    )
}

export default SingleTotal
