import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from './Loader'

const HeatPump = () => {

    const [loading, setLoading] = useState(true);
    const [heatpumps, setHeatpumps] = useState([])

    const fetchHeatpumps = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/heatpumps')
        setHeatpumps(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchHeatpumps();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchHeatpumps, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])

    return (
        <>
            {loading ?
                <Loader />
                :
                heatpumps.map((heatpump, i) => (
                    <div className={`grid--elem heatpump ${heatpump.value > 0 && 'active'}`} key={i}>
                        <span className={`name`}>{heatpump.name}</span>
                        <div className={`value`}>
                            <span>{heatpump.value}</span>
                        </div>
                        <span className={`unit`} title="Einheit">%</span>
                    </div>
                ))
            }
        </>
    )
}

export default HeatPump
