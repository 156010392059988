import React, { useState, useEffect, useContext } from 'react'
import Graph from '../Graph/'
import { GlobalContext } from '../../context/GlobalState'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';

const DetailedView = ({ showDetails, obj, route, unit, close, produced, minValue, hideLive, customStart, customLabel, batteryStatus }) => {

    const { setDetailsView } = useContext(GlobalContext)

    const [param, setParam] = useState(customStart || 'day');
    const [curUnit, setCurUnit] = useState(unit);
    const [prodInterval, setProdInterval] = useState(14);

    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        setDetailsView(showDetails || false);
    }, [showDetails])

    useEffect(() => {
        disableBodyScroll(document.querySelector('.detailed_view'));

        return () => {
            clearAllBodyScrollLocks();
        }
    }, [])

    return (
        <div className={`detailed__view`}>
            <div className={`inner`}>

                <span onClick={() => { close(); setDetailsView(false); }} className={`close`}>&times;</span>

                <div className={`options`}>
                    {!hideLive ?
                        <span className={`current`}>
                            {`aktuell`}&nbsp;<strong>{`${obj.value} ${unit}`}</strong>
                            {batteryStatus ?
                                <span className={`battery__status ${batteryStatus}`} style={{ height: `${obj.value}%` }}></span>
                                : ''}
                        </span>
                        : ''}
                    {produced && !hideLive ?
                        param === 'produced' ?
                            <div className={`view`} onClick={() => { setParam('day'); setCurUnit(unit) }}>
                                Livedaten anzeigen
                        </div> :
                            <div className={`view`} onClick={() => { setParam('produced'); setCurUnit('kWh') }}>
                                Erzeugung anzeigen
                        </div>
                        : ''}
                    <span onClick={() => setRefetch(!refetch)} className={`refetch`}>
                        <CachedRoundedIcon style={{ width: '1.1rem', height: '1.1rem' }} />
                    </span>
                </div>

                <h2 className={`name`}>
                    {customLabel || obj.name}
                </h2>

                {param === 'produced' ?
                    <ul className={`param`}>
                        <li className={prodInterval === 14 ? 'checked' : ''} onClick={() => setProdInterval(14)}>2 Wochen</li>
                        <li className={prodInterval === 62 ? 'checked' : ''} onClick={() => setProdInterval(62)}>2 Monate</li>
                        <li className={prodInterval === 365 ? 'checked' : ''} onClick={() => setProdInterval(365)}>1 Jahr</li>
                        <li className={prodInterval === 'producedTotal' ? 'checked' : ''} onClick={() => setProdInterval('producedTotal')}>Übersicht</li>
                    </ul> :
                    <ul className={`param`}>
                        <li className={param === 'day' ? 'checked' : ''} onClick={() => setParam('day')}>24h</li>
                        <li className={param === 'week' ? 'checked' : ''} onClick={() => setParam('week')}>Woche</li>
                        <li className={param === 'month' ? 'checked' : ''} onClick={() => setParam('month')}>Monat</li>
                    </ul>
                }

                <div className={`graph__wrapper`}>
                    <Graph
                        customLabel={customLabel}
                        hideLive={hideLive}
                        refetch={refetch}
                        id={obj.objID}
                        route={route}
                        param={param}
                        label={obj.name}
                        unit={curUnit}
                        prodInterval={prodInterval}
                        minValue={minValue}
                    />
                </div>

            </div>

            <span onClick={close} className={`overlay`}></span>
        </div>
    )
}

export default DetailedView
