import React, { useState } from 'react'
import DetailedView from '../../widgets/DetailedView'

const SinglePV = ({ pv }) => {

    const [showDetails, setShowDetails] = useState(false);

    const closeDetails = () => {
        setShowDetails(false);
    }

    return (
        <>
            <div onClick={() => setShowDetails(!showDetails)} className={`grid--elem ${pv.objID === 10106 ? 'pv-total' : ''} ${pv.value > 0 ? 'active' : ''}`}>
                <span className={`name`}>{pv.name}</span>
                <div className={`value`}>
                    <span>{pv.value}</span>
                </div>
                <span className={`unit`} title="Einheit">kW</span>
                {pv.value > 0 ?
                    <svg className="day" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 158"><g><g><polygon points="40.2,69.4 21.9,78.7 40.2,88" /><polygon points="111.8,69.4 111.8,88 130.1,78.7" /><polygon points="50.3,49.9 30,47.1 39.8,65.1" /><polygon points="122,47.1 101.7,49.9 112.2,65.1" /><polygon points="94.8,27.6 79.3,41.1 96.5,48.1" /><polygon points="57.2,27.6 55.5,48.1 72.7,41.1" /><polygon points="30,110.9 50.3,108.1 39.8,92.9" /><polygon points="122,110.9 112.2,92.9 101.7,108.1" /><polygon points="96.5,109.9 79.3,116.9 94.8,130.4" /><polygon points="72.7,116.9 55.5,109.9 57.2,130.4" /><circle cx="75.7" cy="79" r="30.9" /></g></g></svg>
                    :
                    <svg className="night" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 158"><g><g><path d="M58.8,25c-0.1,0-0.2,0.1-0.4,0.1c-20,8.6-33.9,28.5-33.9,51.6c0,31,25.2,56.2,56.2,56.2c18.1,0,34.3-8.6,44.6-22c0.4-0.6,0.4-1.4,0-2c-0.4-0.6-1.2-0.8-1.9-0.5c-6.4,2.8-13.5,4.3-20.9,4.3c-29.3,0-53-23.7-53-53c0-12.1,4.1-23.3,10.9-32.3C61.2,26.6,60.1,24.8,58.8,25L58.8,25z" /></g><g><path d="M58.8,25c-0.1,0-0.2,0.1-0.4,0.1c-20,8.6-33.9,28.5-33.9,51.6c0,31,25.2,56.2,56.2,56.2c18.1,0,34.3-8.6,44.6-22c0.4-0.6,0.4-1.4,0-2c-0.4-0.6-1.2-0.8-1.9-0.5c-6.4,2.8-13.5,4.3-20.9,4.3c-29.3,0-53-23.7-53-53c0-12.1,4.1-23.3,10.9-32.3C61.2,26.6,60.1,24.8,58.8,25L58.8,25z" /></g></g></svg>

                }
            </div>

            {showDetails ?
                <DetailedView
                    showDetails={showDetails}
                    obj={pv}
                    route={`pvs`}
                    unit='kW'
                    produced={true}
                    close={closeDetails}
                />
                : ''}
        </>
    )
}

export default SinglePV
