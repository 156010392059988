import React from 'react'
import { Spinner } from '../../widgets/Spinner'

const Loader = (props) => {
    return (
        <>
            <LoaderItem {...props} />
        </>
    )
}

const LoaderItem = (props) => {
    return (
        <div className={`grid--elem full loading ${props.hide ? 'hide' : ''}`}>
            <span className={`name`}>Name</span>
            <div className={`value`}>
                <span><Spinner /></span>
            </div>
        </div>
    )
}

export default Loader
