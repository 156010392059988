import React from 'react'
import BHKW from '../../components/BHKW/BHKW'
import CO2 from '../../components/CO2/CO2'
import Battery from '../../components/BatteryStorage/Battery'
import Total from '../../components/Total/Total'
import PV from '../../components/Photovoltaik/PV'
import HeatEnergy from '../../components/HeatEnergy/HeatEnergy'
import ChargingStation from '../../components/ChargingStation/ChargingStation'
import Pool from '../../components/Pool/Pool'
import Housekeeping from '../../components/Housekeeping/Housekeeping'

const Overview = () => {

    return (
        <div className={`content`}>
            <h1>Übersicht</h1>
            <CO2 />

            <h2>Batteriespeicher</h2>
            <Battery />

            <h2>Gesamt</h2>
            <Total />

            <h2>BHKWs</h2>
            <BHKW />

            <h2>Photovoltaik</h2>
            <PV />

            <h2>Wärmeenergie</h2>
            <HeatEnergy />

            <h2>Ladestationen</h2>
            <ChargingStation />

            <h2>Pools</h2>
            <Pool />

            <h2>Housekeeping</h2>
            <Housekeeping />

        </div>
    )
}

export default Overview
