import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from './Loader'

const Housekeeping = (props) => {

    const [loading, setLoading] = useState(true);
    const [battery, setBattery] = useState([]);
    const [allowed, setAllowed] = useState(true);

    const fetchBattery = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/battery/10061')
        //res.data.sort((a, b) => { return b.value - a.value })
        setBattery(res.data.value)
        let cur = new Date();
        setAllowed(!(cur.getHours() >= 16 || cur.getHours() < 8))
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchBattery();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchBattery, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])

    return (
        <div className={`grid housekeeping`}>
            {loading ?
                <Loader />
                :
                <div className={`grid--elem full`}>
                    <span className={`name`}>Wäscherei</span>
                    <div className={`value`}>
                        <svg
                            className={
                                !allowed ? 'dontwash' :
                                    battery >= 75 ? 'mustwash' :
                                        battery >= 40 ? 'wash' :
                                            battery >= 25 ? 'canwash' :
                                                'dontwash'
                            }
                            id="washing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.394 285.394">
                            <g>
                                <path d="M244.697,0h-204c-8.284,0-15,6.716-15,15v38.696h234V15C259.697,6.716,252.98,0,244.697,0z M96.276,33.849H51.942
                                        c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7h44.334c3.866,0,7,3.134,7,7C103.276,30.715,100.142,33.849,96.276,33.849z
                                        M161.697,36.696c-5.439,0-9.85-4.408-9.85-9.848c0-5.439,4.41-9.849,9.85-9.849c5.44,0,9.849,4.409,9.849,9.849
                                        C171.545,32.288,167.136,36.696,161.697,36.696z M191.697,36.696c-5.439,0-9.85-4.408-9.85-9.848c0-5.439,4.41-9.849,9.85-9.849
                                        c5.44,0,9.849,4.409,9.849,9.849C201.545,32.288,197.136,36.696,191.697,36.696z M221.697,36.696c-5.439,0-9.85-4.408-9.85-9.848
                                        c0-5.439,4.41-9.849,9.85-9.849c5.44,0,9.849,4.409,9.849,9.849C231.545,32.288,227.136,36.696,221.697,36.696z"/>
                                <path d="M25.697,270.394c0,8.284,6.716,15,15,15h204c8.283,0,15-6.716,15-15V76.196h-234V270.394z M142.697,106.612
                                        c38.659,0,70,31.34,70,70c0,38.66-31.341,70-70,70s-70-31.34-70-70C72.697,137.952,104.038,106.612,142.697,106.612z"/>
                                <path d="M126.762,203.798c0.488,0.488,1.128,0.732,1.768,0.732c0.64,0,1.279-0.244,1.768-0.732l50-50
                                        c0.977-0.976,0.977-2.559,0-3.535c-0.977-0.977-2.558-0.977-3.535,0l-50,50C125.786,201.239,125.786,202.822,126.762,203.798z"/>
                                <path d="M103.53,204.53c0.64,0,1.279-0.244,1.768-0.732l50-50c0.977-0.976,0.977-2.559,0-3.535c-0.977-0.977-2.558-0.977-3.535,0
                                        l-50,50c-0.977,0.976-0.977,2.559,0,3.535C102.251,204.286,102.89,204.53,103.53,204.53z"/>
                            </g>
                        </svg>
                    </div>
                </div>
            }
        </div>
    )
}

export default Housekeeping