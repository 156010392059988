import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SinglePool from './SinglePool'

const Pool = (props) => {

    const { openDetailsView } = useContext(GlobalContext)

    const [loading, setLoading] = useState(true);
    const [poolvalues, setPoolvalues] = useState([])

    const fetchPools = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/pools')
        setPoolvalues(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchPools();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchPools, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])

    return (
        <div className={`grid pools`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            {loading ?
                <Loader />
                :
                poolvalues.map((pool, i) => (
                    <SinglePool pool={pool} key={i} />
                ))
            }
        </div>
    )
}

export default Pool