import React from 'react'
import { Link } from "react-router-dom";
import { Logo } from '../../widgets/Logo/'

const Home = () => {
    return (
        <div className={`home`}>
            <Logo loading={true} size={15} style={{ margin: '3rem 0', minWidth: '10rem', maxWidth: '12rem' }} />
            <Link to='/overview'>Zur Übersicht<span></span></Link>
        </div>
    )
}

export default Home