import React from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'

const Header = () => {

    return (
        <>
            <header className={`main--header`}>
                <div className="logo--wrapper">
                    <Link to='/'><h1 className="logo beta">Haffhus Energy</h1></Link>
                </div>
            </header>
            <div id="overlay"></div>
        </>
    )
}

Header.propTypes = {
    color: PropTypes.string
}

export { Header }
