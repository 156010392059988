import React, { useState, useEffect } from 'react'

const Temperature = ({ bs }) => {

    const [temperatures, setTemperatures] = useState(bs[0].temperatures)
    const [show, setShow] = useState(0);

    const [highTemps, setHighTemps] = useState(temperatures.slice(0, 3))
    const [lowTemps, setLowTemps] = useState(temperatures.slice(temperatures.length - 3))

    useEffect(() => {
        setTemperatures(bs[0].temperatures)
    }, [bs])

    useEffect(() => {
        setHighTemps(temperatures.slice(0, 3))
        setLowTemps(temperatures.slice(temperatures.length - 3))
    }, [temperatures])

    useEffect(() => {
        initFade(lowTemps);
        initFade(highTemps);
    }, [])

    const initFade = (arr) => {
        let cur = show;
        let length = arr.length - 1;

        setInterval(() => {
            if (cur >= length) {
                cur = 0;
                setShow(cur);
            } else {
                cur++;
                setShow(cur);
            }
        }, 5000);
    }

    return (
        <>
            <div className={`grid--elem temperatures`}>
                <span className={`name`}>Pufferspeicher Temp. {show + 1}</span>
                {highTemps.map((temp, i) => (
                    <div key={i} className={`value ${i === show ? 'show' : ''}`}>
                        <span className={
                            temp.value >= 65 ? 'ok' :
                                temp.value >= 60 ? 'moderate' :
                                    'critical'
                        }>{temp.value}</span>
                    </div>
                ))}
                <span className={`unit`} title="Einheit">&deg;C</span>
            </div>
            <div className={`grid--elem temperatures`}>
                <span className={`name`}>Pufferspeicher Temp. {show + temperatures.length - 2}</span>
                {lowTemps.map((temp, i) => (
                    <div className={`value ${i === show ? 'show' : ''}`} key={i}>
                        <span>{temp.value}</span>
                    </div>
                ))}
                <span className={`unit`} title="Einheit">&deg;C</span>
            </div>
        </>
    )
}

export default Temperature
