import React, { useState } from 'react'
import DetailedView from '../../widgets/DetailedView'

const SingleBHKW = ({ bhkw }) => {

  const [showDetails, setShowDetails] = useState(false);

  const closeDetails = () => {
    setShowDetails(false);
  }

  const glock1 = bhkw.objID === 28554
  const glock2 = bhkw.objID === 10142
  const isGlock = (glock1 || glock2)

  return (
    <>
      <div onClick={() => setShowDetails(!showDetails)} className={`grid--elem ${glock1 ? 'glock glock1' : glock2 ? 'glock glock2' : 'dachs'} ${bhkw.value > 0 ? 'active' : ''}`}>
        <span className={`name`}>{bhkw.name}</span>
        <div className={`value`}>
          <span className={
            (isGlock && bhkw.value >= 20) ? 'ok' :
              (isGlock && bhkw.value >= 18) ? 'moderate' :
                (isGlock && bhkw.value < 18 && bhkw.value > 0) ? 'critical' : ''
          }>{bhkw.value}</span>
        </div>
        <span className={`unit`} title="Einheit">kW</span>
        <svg id="glocksvg" className="bhkwsvg" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path className="gear" d="m475.082031 205.300781c-5.609375-24.34375-15.101562-47.269531-28.316406-68.378906l19.03125-31.742187-58.976563-58.976563-31.742187 19.03125c-21.109375-13.214844-44.035156-22.707031-68.378906-28.316406l-8.980469-36.917969h-83.4375l-8.980469 36.917969c-24.34375 5.609375-47.269531 15.101562-68.378906 28.316406l-31.742187-19.03125-58.976563 58.976563 19.03125 31.742187c-13.214844 21.109375-22.707031 44.035156-28.316406 68.378906l-36.917969 8.980469v83.4375l36.917969 8.980469c5.609375 24.34375 15.101562 47.269531 28.316406 68.378906l-19.03125 31.742187 58.976563 58.976563 31.742187-19.03125c21.109375 13.214844 44.035156 22.707031 68.378906 28.316406l8.980469 36.917969h83.4375l8.980469-36.917969c24.34375-5.609375 47.269531-15.101562 68.378906-28.316406l31.742187 19.03125 58.976563-58.976563-19.03125-31.742187c13.214844-21.109375 22.707031-44.035156 28.316406-68.378906l36.917969-8.980469v-83.4375zm-219.082031 215.699219c-90.980469 0-165-74.019531-165-165s74.019531-165 165-165 165 74.019531 165 165-74.019531 165-165 165zm0 0" /><path className="flash" d="m271 121.921875-117.816406 179.078125h87.816406v89.078125l117.816406-179.078125h-87.816406zm0 0" /></svg>
      </div>

      {showDetails && (
        <DetailedView
          showDetails={showDetails}
          obj={bhkw}
          route={`bhkws`}
          unit='kW'
          produced={true}
          minValue={0}
          close={closeDetails}
        />
      )}
    </>
  )
}

export default SingleBHKW
