import React, { useContext } from 'react'
import { Toggle } from '../Toggle/'
import { GlobalContext } from '../../context/GlobalState'

const Settings = () => {

    const { darkMode, switchTheme } = useContext(GlobalContext)

    return (
        <div className={`settings`}>
            <ul>
                <li>
                    <span>Dark Mode</span>
                    <Toggle state={darkMode} handler={switchTheme} />
                </li>
            </ul>
        </div>
    )
}

export default Settings
