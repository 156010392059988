import React from 'react'
import PropTypes from 'prop-types'

const PoolCover = (props) => {
    return (
        <svg id="PoolCover" className={`${props.className ? props.className : ''} ${props.covered ? 'covered' : 'closed'}`} viewBox="0 0 283.218 283.218" xmlns="http://www.w3.org/2000/svg">
            <title>{props.title || 'Das Dach des Außenpools'} ist derzeit {props.covered ? 'geschlossen' : 'geöffnet'}</title>
            <g>
                <g className={`roof`}>
                    <path d="M275.959,103.693L145.481,29.589c-4.487-2.557-10.004-2.499-14.433,0.157l-124.06,74.11
			c-6.798,4.063-9.019,12.86-4.957,19.656c4.062,6.8,12.865,9.024,19.66,4.957l116.887-69.822l123.221,69.981
			c2.235,1.269,4.667,1.876,7.061,1.876c4.989,0,9.844-2.614,12.48-7.264C285.26,116.357,282.843,107.606,275.959,103.693z"/>
                </g>
                <g className={`pool`}>
                    <path d="M119.75,127.633c-1.36-1.421-3.125-2.206-4.961-2.206c-10.944,0-14.547,12.891-14.949,20.51
			c-0.143,2.744,1.965,5.092,4.713,5.241c2.787,0.149,5.099-1.97,5.241-4.719c0.14-2.669,1.295-8.391,3.438-10.37
			c2.025,6.1,2.968,23.993,2.992,44.249c-0.187,0.518-0.306,1.078-0.306,1.666c0,0.583,0.119,1.12,0.301,1.647
			c-0.019,4.882-0.096,9.852-0.21,14.817c3.384,0.719,7.096,0.299,10.03-1.325c0.082-3.248,0.149-6.618,0.187-10.155h32.609
			c-0.023,2.875-0.089,5.777-0.146,8.676c0.934,0.443,1.862,0.863,2.786,1.391c2.101,1.209,4.625,1.788,7.15,1.773
			c0.304-11.635,0.448-26.061-0.154-39.003c0.206-0.541,0.332-1.119,0.332-1.735c0-0.798-0.206-1.545-0.537-2.217
			c-0.812-13.512-2.544-24.743-5.89-28.231c-1.358-1.421-3.122-2.203-4.957-2.203c-10.948,0-14.542,12.888-14.946,20.512
			c-0.143,2.74,1.965,5.088,4.713,5.236c2.803,0.149,5.095-1.969,5.239-4.718c0.14-2.67,1.297-8.392,3.439-10.368
			c1.022,3.066,1.764,9.168,2.254,17.009h-32.662C124.583,140.84,122.867,130.881,119.75,127.633z M158.578,163.06
			c0.149,4.401,0.237,9.073,0.271,13.955h-32.583c-0.021-4.672-0.11-9.386-0.278-13.955H158.578z"/>
                    <path d="M80.441,200.869c-3.22,1.862-6.905,2.842-10.652,2.842c-3.96,0-7.169,3.211-7.169,7.169c0,3.953,3.209,7.169,7.169,7.169
			c6.256,0,12.417-1.647,17.812-4.752c4.889-2.823,8.875-2.813,13.766,0c10.791,6.217,24.827,6.217,35.622,0
			c4.889-2.823,8.877-2.813,13.763,0c11.099,6.395,25.566,6.193,36.506-0.527c5.082-3.122,8.508-3.122,13.586,0.005
			c5.619,3.449,12.083,5.278,18.691,5.278c3.963,0,7.169-3.211,7.169-7.168c0-3.958-3.206-7.169-7.169-7.169
			c-3.957,0-7.831-1.097-11.187-3.15c-9.759-6.002-18.841-5.997-28.596-0.005c-6.538,4.019-15.224,4.141-21.847,0.313
			c-9.311-5.362-18.761-5.367-28.077,0c-6.445,3.715-14.867,3.715-21.305,0C99.205,195.506,89.756,195.501,80.441,200.869z"/>
                    <path d="M48.317,219.696c0-3.958-3.206-7.169-7.168-7.169s-7.169,3.211-7.169,7.169c0,19.741,16.062,35.806,35.809,35.806
			c6.256,0,12.417-1.647,17.812-4.751c4.889-2.819,8.875-2.819,13.766,0c10.791,6.216,24.827,6.216,35.622,0
			c4.889-2.819,8.877-2.819,13.763,0c11.099,6.394,25.566,6.193,36.506-0.527c5.082-3.118,8.508-3.118,13.586,0.005
			c5.619,3.453,12.083,5.278,18.691,5.278c19.747,0,35.811-16.064,35.811-35.806c0-3.958-3.206-7.169-7.168-7.169
			c-3.963,0-7.169,3.211-7.169,7.169c0,11.835-9.633,21.468-21.474,21.468c-3.957,0-7.831-1.097-11.187-3.15
			c-9.759-6.001-18.841-5.997-28.596-0.004c-6.538,4.018-15.224,4.14-21.847,0.312c-9.311-5.358-18.761-5.358-28.077,0
			c-6.445,3.715-14.867,3.715-21.305,0c-9.309-5.358-18.762-5.358-28.077,0c-3.22,1.862-6.905,2.842-10.653,2.842
			C57.946,241.164,48.317,231.527,48.317,219.696z"/>
                </g>
            </g>
        </svg>
    )
}

PoolCover.propTypes = {
    covered: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string
}

PoolCover.defaultProps = {
    covered: false
}

export { PoolCover }
