import React, { useState, useEffect } from 'react'
import { Spinner } from '../Spinner/'
import axios from 'axios'
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, Tooltip, Cell, XAxis } from 'recharts'

const Graph = ({ id, label, unit, param, route, prodInterval, minValue, refetch, hideLive }) => {

    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState([])

    const [focusBar, setFocusBar] = useState(null);

    const fetchValues = async () => {
        setLoading(true);

        const res = await axios(`https://energie.haffhus.de/api/v1/${route}/${id}/${param || 'day'}`)

        if (minValue !== null) {
            res.data[`${param}`].forEach(val => {
                if (val.value < minValue) {
                    val.value = minValue;
                }
                if (val.difference < minValue) {
                    val.difference = minValue;
                }
            })
        }
        param === 'produced' ?
            prodInterval === 'producedTotal' ?
                setValues(res.data[`${param}`].reverse()) :
                setValues(res.data[`${param}`].slice(0, prodInterval || 14).reverse()) :
            setValues(res.data[`${param}`].reverse())

        setLoading(false);
    }

    useEffect(() => {
        if (param) {
            fetchValues();
        }
    }, [param, prodInterval, refetch])

    if (!hideLive && id === '25916') {
        values.map(value => (value.value / 1.41).toFixed(1));
    }


    const data = values;

    return (
        <div className={`graph`}>
            {
                loading ? <Spinner />
                    :
                    !(values.length > 0) ?
                        <span className={`no__values`} style={{ textAlign: 'center', padding: '1rem 1.5rem' }}>
                            Diese Werte stehen Ihnen derzeit leider noch nicht zur Verfügung
                        </span>
                        :
                        <ResponsiveContainer height={`100%`} width={`100%`}>
                            {param === 'produced' && prodInterval !== 'producedTotal' ?
                                <BarChart data={data}
                                    onMouseMove={state => {
                                        if (state.isTooltipActive) {
                                            setFocusBar(state.activeTooltipIndex);
                                        } else {
                                            setFocusBar(null);
                                        }
                                    }}
                                    onClick={() => { }}
                                >
                                    <defs>
                                        <linearGradient id="mainColor" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#6ada87" stopOpacity={0.9} />
                                            <stop offset="100%" stopColor="#6ada87" stopOpacity={0.05} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" inverval={0} tick axisLine={false} tickFormatter={(e) => new Date(e).toLocaleDateString('de-DE', dateoptions)} />
                                    <Bar dataKey={'difference'} fill="#6ada87" unit={unit}>
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={focusBar === index ? '#508f5f' : '#6ada87'} />
                                        ))}
                                    </Bar>
                                    <Tooltip cursor={false} content={<CustomTooltip unit={unit} param={param} />} />
                                </BarChart>
                                :
                                <AreaChart data={data} onClick={() => { }}>
                                    <defs>
                                        <linearGradient id="mainColor" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#6ada87" stopOpacity={0.8} />
                                            <stop offset="50%" stopColor="#6ada87" stopOpacity={0.8} />
                                            <stop offset="100%" stopColor="#6ada87" stopOpacity={0.2} />
                                        </linearGradient>
                                    </defs>
                                    <Area
                                        type="monotone"
                                        activeDot={{ fill: '#6ada98', stroke: '#fff', strokeWidth: 0, r: 3 }}
                                        dataKey={prodInterval === 'producedTotal' && param === 'produced' ? 'total' : 'value'}
                                        stroke="#6ada87"
                                        strokeWidth={2}
                                        fill="url(#mainColor)"
                                        unit={unit}
                                    />
                                    {prodInterval === 'producedTotal' && param === 'produced' ?
                                        <XAxis dataKey="date" inverval={0} tick axisLine={false} tickFormatter={(e) => new Date(e).toLocaleDateString('de-DE', dateoptions)} />
                                        : ''}
                                    <Tooltip content={<CustomTooltip unit={unit} param={param} prodInterval={prodInterval} />} />
                                </AreaChart>
                            }
                        </ResponsiveContainer>
            }
        </div>
    )
}

const dateoptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const CustomTooltip = ({ active, payload, unit, param, prodInterval }) => {
    if (active && payload) {
        return (
            <div className="tooltip">
                <p className="value">
                    <strong>{`${prodInterval === 'producedTotal' ? unit === 'kg' ? (payload[0].value / 1000).toFixed(1) : payload[0].value.toFixed(0) : payload[0].value.toFixed(2)} ${prodInterval === 'producedTotal' && unit === 'kg' ? 't' : unit}`}</strong>
                </p>
                <p className="datetime">
                    {param === 'produced' ?
                        `${new Date(payload[0].payload.date).toLocaleDateString('de-DE', dateoptions)}`
                        :
                        `${new Date(payload[0].payload.date).toLocaleDateString('de-DE', dateoptions)} – ${payload[0].payload.time.slice(0, 5)} Uhr`
                    }
                </p>
            </div>
        );
    }

    return null;
};

export default Graph
