import React, { useState, useEffect } from 'react'
import DetailedView from '../../widgets/DetailedView'

const SinglePV = ({ battery }) => {

    const [showDetails, setShowDetails] = useState(false);
    const [status, setStatus] = useState('');

    const closeDetails = () => {
        setShowDetails(false);
    }

    useEffect(() => {
        if ((battery.objID === 10061 && battery.value >= 90) || (battery.objID === 10061 && battery.value < 15)) {
            setStatus('critical')
        } else if ((battery.objID === 10061 && battery.value >= 80) || (battery.objID === 10061 && battery.value < 30)) {
            setStatus('moderate')
        } else {
            setStatus('ok')
        }
    }, [battery])

    return (
        <>
            <div onClick={() => setShowDetails(!showDetails)}
                className={`grid--elem full ${battery.value > 0 ? 'active' : ''} ${status}`}>

                <span className={`name`}>{battery.name}</span>
                <div className={`value`}>
                    <span>{battery.value}</span>
                </div>
                <span className={`unit`} title="Einheit">%</span>
                <span className={`battery__status`} style={{ height: `${battery.value}%` }}>
                </span>

            </div>

            {showDetails ?
                <DetailedView showDetails={showDetails} obj={battery} route={`battery`} unit='%' close={closeDetails} batteryStatus={status} />
                : ''}
        </>
    )
}

export default SinglePV
