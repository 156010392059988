import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import DetailedView from '../../widgets/DetailedView'

const CO2 = () => {

    const { openDetailsView } = useContext(GlobalContext)

    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState([]);
    const [showDetails, setShowDetails] = useState(false);

    const fetchCO2 = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/total/25916')
        //res.data.sort((a, b) => { return b.value - a.value })
        setTotal(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchCO2();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchCO2, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])

    const closeDetails = () => {
        setShowDetails(false);
    }

    return (
        <div className={`grid co2`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            {loading ?
                <Loader />
                :
                <>
                    <div onClick={() => setShowDetails(!showDetails)} className={`grid--elem full`}>
                        <span className={`name`}>Heutige CO<sub>2</sub>-Vermeidung</span>
                        <div className={`value`}>
                            <span>{(total.lastDay / 1.41).toFixed(1)}</span>
                        </div>
                        <span className={`unit`} title="Einheit">kg</span>
                    </div>

                    {showDetails ?
                        <DetailedView
                            showDetails={showDetails}
                            obj={total}
                            customLabel={'CO2-Vermeidung'}
                            produced={true}
                            hideLive={true}
                            route={`total`}
                            unit='kg'
                            close={closeDetails}
                            customStart={`produced`}
                        />
                        : ''}
                </>
            }
        </div>
    )
}

export default CO2
