import React from 'react'
import PropTypes from 'prop-types'

const Spinner = (props) => {
    return (
        <svg className={`spinner`} viewBox="0 0 50 50" style={{ width: `${props.radius || '50px'}`, height: `${props.radius || '50px'}`, margin: `${props.radius ? null : '-25px 0 0 -25px'}`, marginLeft: `${props.radius ? 'calc(' + props.radius + '/ (-2))' : null}`, marginTop: `${props.radius ? 'calc(' + props.radius + '/ (-2))' : null}` }}>
            <circle className={`path`} cx="25" cy="25" r="20" fill="none" stroke={`${props.color ? props.color : '#6ada87'}`} strokeWidth="5"></circle>
        </svg>
    )
}

Spinner.propTypes = {
    color: PropTypes.string,
    radius: PropTypes.string
}

export { Spinner }
