import React from 'react'
import { Spinner } from '../../widgets/Spinner'

const Loader = (props) => {
  return (
    <>
      <LoaderItem className={`glock glock1`} {...props} />
      <LoaderItem className={`glock glock2`} {...props} />
      <LoaderItem {...props} />
      <LoaderItem {...props} />
      <LoaderItem {...props} />
      <LoaderItem {...props} />
    </>
  )
}

const LoaderItem = (props) => {
  return (
    <div className={`grid--elem ${props.className ? props.className : ''} loading ${props.hide ? 'hide' : ''}`}>
      <span className={`name`}>Name</span>
      <div className={`value`}>
        <span><Spinner /></span>
      </div>
      <span className={`unit`} title="Einheit">kW</span>
    </div>
  )
}

export default Loader
