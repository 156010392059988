import React, { useState } from 'react'
import Settings from '../Settings/'
import HomeIcon from '@material-ui/icons/HomeRounded';
//import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
//import FilterListIcon from '@material-ui/icons/FilterListRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import TVIcon from '@material-ui/icons/TvRounded';
import { NavLink } from 'react-router-dom'

const Sidebar = ({ toggleNav, openNav }) => {

    const [openSettings, setOpenSettings] = useState(false);

    return (
        <div className={`sidebar`}>
            <div className={`sidebar__std`}>
                <span>
                    <NavLink to='/overview' activeClassName="active">
                        <HomeIcon />
                    </NavLink>
                </span>
                <span>
                    <NavLink to='/screen' activeClassName="active">
                        <TVIcon className={`small`} />
                    </NavLink>
                </span>
                {/* <span>
                    <NavLink to='/network' activeClassName="active">
                        <AccountTreeRoundedIcon className={`small`} />
                    </NavLink>
                </span> */}
                <span className={`filter`}>
                    <a href='https://www.haffhus.de/docs/energie-api'>
                        <MenuBookRoundedIcon />
                    </a>
                </span>
                <span className={`settings__toggle filter ${openSettings ? 'opened' : ''}`}>
                    <SettingsRoundedIcon onClick={() => setOpenSettings(!openSettings)} />
                    {openSettings ?
                        <Settings />
                        : ''}
                </span>
                {/* <span onClick={toggleNav} className={`${openNav ? 'filter opened' : 'filter'}`}>
                    <FilterListIcon />
                </span> */}
            </div>
            {/* <div className={`sidebar__ext ${openNav ? 'opened' : ''}`}>
                <div style={{ padding: '3rem 2rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <span role="img" aria-label="Rocket" style={{ fontSize: '2.5rem', marginBottom: '0.5rem' }}>🚀</span>
                    <span>Coming soon...</span>
                </div>
            </div> */}
        </div>
    )
}

export default Sidebar
