import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleBHKW from './SingleBHKW'

const BHKW = (props) => {

    const { openDetailsView } = useContext(GlobalContext)

    const [loading, setLoading] = useState(true);
    const [bhkws, setBhkws] = useState([])

    const fetchBHKWs = async () => {
        const res = await axios(`https://energie.haffhus.de/api/v1/bhkws`)
        //res.data.sort((a, b) => { return b.value - a.value })
        setBhkws(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchBHKWs();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchBHKWs, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])


    return (
        <div className={`grid bhkw`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            {loading ?
                <Loader />
                :
                bhkws.map((bhkw, i) => (
                    <SingleBHKW bhkw={bhkw} key={i} />
                ))
            }
        </div>
    )
}

export default BHKW
