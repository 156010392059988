import React from 'react'
import { ReactComponent as NetworkGraphic } from '../../assets/icons/network.svg'

const Network = () => {
    return (
        <div className={`content`}>
            <h1>
                Network Structure
            </h1>
            <div className={`network__wrapper`}>
                <NetworkGraphic />
            </div>
        </div>
    )
}

export default Network
