import React, { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import WoodChipBoiler from '../WoodChipBoiler/WoodChipBoiler'
import HeatPump from '../HeatPump/HeatPump'
import BufferStorage from '../BufferStorage/BufferStorage'

const HeatEnergy = () => {

    const { openDetailsView } = useContext(GlobalContext)

    return (
        <div className={`heat grid`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            <WoodChipBoiler />
            <HeatPump />
            <BufferStorage />
        </div>
    )
}

export default HeatEnergy
