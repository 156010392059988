export default (state, action) => {
    switch (action.type) {
        case 'SWITCH_THEME':
            return {
                ...state,
                darkMode: !state.darkMode
            }
        case 'TOGGLE_NAV':
            return {
                ...state,
                openNav: !state.openNav
            }
        case 'TOGGLE_DETAILS_VIEW':
            return {
                ...state,
                openDetailsView: action.payload
            }
        default:
            return state;
    }
}