import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleTotal from './SingleTotal'

const Total = (props) => {

    const { openDetailsView } = useContext(GlobalContext)

    const [loading, setLoading] = useState(true);
    const [totalvalues, setTotalvalues] = useState([])

    const fetchTotals = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/total')
        setTotalvalues(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchTotals();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchTotals, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])

    return (
        <div className={`grid gesamt`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            {loading ?
                <Loader />
                :
                totalvalues.map((total, i) => (
                    <SingleTotal total={total} key={i} />
                ))
            }
        </div>
    )
}

export default Total