import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { GlobalProvider } from './context/GlobalState'
import './App.scss';
import { Header } from './widgets/Header/'
import Sidebar from './widgets/Sidebar/'
import Home from './routes/Home/Home'
import Overview from './routes/Overview/Overview'
import Screen from './routes/Screen/Screen'
import Network from './routes/Network/Network'

function App() {

  return (
    <GlobalProvider>
      <BrowserRouter>

        <Switch>

          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/overview">
            <Header />
            <Sidebar />
            <Overview />
          </Route>

          <Route path="/network">
            <Header />
            <Sidebar />
            <Network />
          </Route>

          <Route path="/screen">
            <Screen />
          </Route>

        </Switch>

      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
