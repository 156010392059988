import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleBattery from './SingleBattery'

const Battery = () => {

    const { openDetailsView } = useContext(GlobalContext)

    const [loading, setLoading] = useState(true);
    const [batteries, setBatteries] = useState([]);

    const fetchBatteries = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/battery')
        //res.data.sort((a, b) => { return b.value - a.value })
        setBatteries(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchBatteries();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchBatteries, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])
    return (
        <div className={`grid soc`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            {loading ?
                <Loader />
                :
                batteries.map((battery, i) => (
                    <SingleBattery battery={battery} key={i} />
                ))
            }
        </div>
    )
}

export default Battery
