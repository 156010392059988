import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from './Loader'
import Temperatures from './Temperature'

const BufferStorage = (props) => {

    const [loading, setLoading] = useState(true);
    const [bufferstorages, setBSs] = useState([])

    const fetchBSs = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/bufferstorages')
        setBSs(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchBSs();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchBSs, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])


    return (
        <>
            {loading ?
                <Loader />
                :
                <>
                    {/* {bufferstorages.map((bs, i) => (
                        <div className={`grid--elem ${bs.value > 0 ? 'active' : ''} ${showGraph ? 'withgraph' : ''}`} key={i}>
                            <span className={`name`}>{bs.name}</span>
                            <span onClick={() => setShowGraph(!showGraph)} className={`info`}>i</span>
                            {showGraph ?
                                <Graph id={`${bs.objID}`} interval={interval} label={`${bs.name}`} unit='kW' />
                                :
                                <>
                                    <div className={`value`}>
                                        <span>{bs.value}</span>
                                    </div>
                                    <span className={`unit`} title="Einheit">&deg;C</span>
                                </>
                            }
                        </div>
                    ))} */}
                    <Temperatures bs={bufferstorages} />
                </>
            }
        </>
    )
}

export default BufferStorage
