import React from 'react'
import PropTypes from 'prop-types'

const Toggle = ({ state, handler }) => {

    return (
        <div className="toggle-switch-wrapper">
            <label className="toggle-switch" htmlFor="checkbox">
                <input
                    type="checkbox"
                    checked={state}
                    onChange={(e) => e.preventDefault()}
                />
                <div className="slider round" onClick={handler}></div>
            </label>
        </div>
    )
}

Toggle.propTypes = {
    state: PropTypes.bool.isRequired,
    handler: PropTypes.func.isRequired
}

export { Toggle }
