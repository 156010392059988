import React, { createContext, useReducer } from 'react'
import AppReducer from './AppReducer'

/* Initial State */
const initialState = {
    darkMode: true,
    openNav: false,
    openDetailsView: false
}

/* Context */
export const GlobalContext = createContext(initialState)

/* Provider */
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState)

    /* Actions */
    async function switchTheme() {
        await dispatch({
            type: 'SWITCH_THEME'
        })
        if (!state.darkMode) {
            document.documentElement.setAttribute('data-theme', 'dark')
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-theme', 'light')
            localStorage.setItem('theme', 'light');
        }
    }

    function toggleNav() {
        dispatch({
            type: 'TOGGLE_NAV'
        })
    }

    function setDetailsView(status) {
        dispatch({
            type: 'TOGGLE_DETAILS_VIEW',
            payload: status
        })
    }

    return (
        <GlobalContext.Provider value={{
            darkMode: state.darkMode, switchTheme,
            openNav: state.openNav, toggleNav,
            openDetailsView: state.openDetailsView, setDetailsView

        }}>
            {children}
        </GlobalContext.Provider>
    )
}


