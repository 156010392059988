import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleCS from '../ChargingStation/SingleCS';

const ChargingStation = () => {

    const { openDetailsView } = useContext(GlobalContext)

    const [loading, setLoading] = useState(true);
    const [chargingStations, setChargingStations] = useState([])

    const fetchChargingStations = async () => {
        const res = await axios('https://energie.haffhus.de/api/v1/chargingstations')
        setChargingStations(res.data)
    }

    useEffect(() => {
        const initLoop = async () => {
            await fetchChargingStations();
            setTimeout(() => {
                setLoading(false);
            }, 1500)
            setInterval(fetchChargingStations, 10000)
        }

        initLoop();

        return () => {
            clearInterval(initLoop)
        }
    }, [])

    return (
        <div className={`grid chargingstation`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
            {loading ?
                <Loader />
                :
                chargingStations.map((cs, i) => (
                    <SingleCS cs={cs} key={i} />
                ))
            }
        </div>
    )
}

export default ChargingStation
